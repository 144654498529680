<template>
  <k-text-display :field="field">
    <div v-html="getLocationsAndDepartmentsHtml()" />
  </k-text-display>
</template>

<script>
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';

export default {
  name: 'LocationDepartmentTreeDisplay',
  components: { KTextDisplay },
  props: {
    allLocationsAndDepartments: {
      Type: Array,
      required: true,
    },
    locations: {
      Type: Array,
      default: () => [],
    },
    departments: {
      Type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: 'locationAndDepartments',
    },
  },
  methods: {
    createLocationsAndDepartmentsArray(locations, departments) {
      // Add all locations with empty departments arrays, actually indicating that all departments are selected
      const locationsAndDepartmentsArray = locations.map(location => ({
        name: location.name,
        departments: [],
      }));
      // Add all departments with location they belong to
      departments.forEach(department => {
        // First find location of the department
        const locationOfDepartment = this.allLocationsAndDepartments.find(location => location.departments.find(locationDepartment => locationDepartment.id === department.id));
        if (!locationOfDepartment) {
          return;
        }
        const locationInArray = locationsAndDepartmentsArray.find(location => location.name === locationOfDepartment.name);
        if (locationInArray) {
          // push department to existing location
          locationInArray.departments.push(department.name);
          return;
        }
        // add new location and add department to it
        locationsAndDepartmentsArray.push({
          name: locationOfDepartment.name,
          departments: [department.name],
        });
      });
      // sort alphabetically
      return locationsAndDepartmentsArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    getLocationsAndDepartmentsHtml() {
      const locationsAndDepartmentsArray = this.createLocationsAndDepartmentsArray(this.locations, this.departments);
      let html = '';
      locationsAndDepartmentsArray.forEach(location => {
        if (location.departments.length === 0) {
          html += `<span class="text-primary">${location.name}: </span>`;
          html += this.$t('person.allDepartments');
        } else {
          html += `<span class="text-primary">${location.name}: </span>`;
          location.departments.forEach((department, idx) => {
            html += `<span class="text--primary">${department}</span>`;
            html += idx < location.departments.length - 1 ? ', ' : '';
          });
        }
        html += '<br />';
      });
      return html;
    },
  },
};
</script>
