<template>
  <page-layout v-if="person">
    <template #header>
      <k-title>{{ fullName }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('person.title') }) }}
      </v-btn>
      <PersonForm
        v-model="updateDialog"
        :employer="employer"
        :request="updateRequest"
        :title="$tc('person.title',1)"
        :values="updateFormValues"
        is-update-form
        @change="updatePersonData()"
      />
    </template>
    <template
      v-if="person"
      #default
    >
      <v-row>
        <v-col cols="6">
          <k-dossier>
            <h3 class="text-h3 primary--text text-truncate d-inline-flex mb-2">
              {{ $t('person.tabs.default') }}
            </h3>
            <k-display-group language-prefix="person.fields">
              <v-row no-gutters>
                <v-col>
                  <k-text-display field="name">
                    {{ fullName }}
                  </k-text-display>
                  <KLinkDisplay
                    :href="person.contactDetail.email"
                    field="contactDetail.email"
                    link-type="email"
                  />
                  <KLinkDisplay
                    :href="person.contactDetail.phone"
                    field="contactDetail.phone"
                    link-type="phone"
                  />
                  <KLinkDisplay
                    :href="person.contactDetail.mobilePhone"
                    field="contactDetail.mobilePhone"
                    link-type="phone"
                  />
                  <k-text-display
                    field="position"
                    :value="person.position"
                  />
                  <LocationDepartmentTreeDisplay
                    v-if="person.locations.length > 0 || person.departments.length > 0"
                    :all-locations-and-departments="allLocationsAndDepartments"
                    :locations="person.locations"
                    :departments="person.departments"
                  />
                </v-col>
                <v-col cols="4">
                  <VImg
                    v-if="person.profilePicture"
                    :src="`${person.profilePicture.url}&type=small`"
                    :lazy-src="`${person.profilePicture.url}&type=lazy`"
                    class="mb-3"
                    width="200"
                  />
                </v-col>
              </v-row>
            </k-display-group>
          </k-dossier>
        </v-col>
        <v-col cols="6">
          <InternalNote
            v-if="can('person.internalNote')"
            scope="person"
            :scope-id="person.id"
          />
        </v-col>
      </v-row>
    </template>
  </page-layout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import PageLayout from '@/components/layout/PageLayout.vue';
import Person from '@/application/models/Person';
import KTitle from '@/components/layout/KTitle.vue';
import PersonForm from '@/modules/person/components/PersonForm.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KDossier from '@/components/layout/KDossier.vue';
import { locationTree } from '@/modules/employer/api/employer.js';
import { show, update } from '@/modules/person/api/person';
import LocationDepartmentTreeDisplay from '@/modules/location/components/LocationDepartmentTreeDisplay.vue';
import { mapGetters } from 'vuex';
import InternalNote from '@/modules/internalNote/components/InternalNote.vue';

export default {
  name: 'Dossier',
  components: {
    InternalNote,
    LocationDepartmentTreeDisplay,
    KDossier,
    KDisplayGroup,
    KTextDisplay,
    KTitle,
    KLinkDisplay,
    PageLayout,
    PersonForm,
  },
  props: {
    employer: {
      type: Object,
    },
  },
  data: () => ({
    updateFormValues: new Person(),
    person: null,
    updateDialog: false,
    locationsAndDepartments: null,
    allLocationsAndDepartments: [],
  }),
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    fullName() {
      const insertion = this.person?.insertion ? ` ${this.person.insertion} ` : ' ';
      return `${this.person?.firstName}${insertion}${this.person.lastName}`;
    },
  },
  watch: {
    '$route.params.personId': {
      immediate: true,
      async handler() {
        const [locationDepartmentResponse, personResponse] = await Promise.all([
          this.getLocationsAndDepartmentsTree(), this.getPerson()],
        );
        this.allLocationsAndDepartments = locationDepartmentResponse;
        this.person = personResponse;
        this.setBreadCrumbs();
        if (this.can('person.internalNote')) {
          eventBus.$emit('internalNote', {
            scope: 'person',
            scopeId: this.person.id,
          });
        }
      },
    },
  },
  beforeDestroy() {
    eventBus.$emit('internalNote');
  },
  methods: {
    async getPerson() {
      const response = await show(parseInt(this.$route.params.personId));
      return response.data.data;
    },
    async openUpdate() {
      this.updateFormValues = new Person();
      const personData = await this.getPerson();
      this.updateFormValues.mapResponse(personData);
      this.updateDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async updatePersonData() {
      this.allLocationsAndDepartments = await this.getLocationsAndDepartmentsTree();
      this.person = await this.getPerson();
      this.setBreadCrumbs();
    },
    async getLocationsAndDepartmentsTree() {
      const response = await locationTree(this.employer.id);
      return response.data.data;
    },
    setBreadCrumbs() {
      eventBus.$emit('setBreadcrumbs', [
        {
          exact: true,
          to: { name: 'employer.index' },
          text: this.$tc('employer.title', 2),
        },
        {
          exact: true,
          to: { name: 'employer.show' },
          text: this.employer.name,
        },
        {
          exact: true,
          to: { name: 'employer.persons' },
          text: this.$tc('person.title', 2),
        },
        {
          exact: true,
          to: { name: 'employer.person' },
          text: this.fullName,
        },
      ]);
    },
  },
}
;
</script>

<style scoped>

</style>
